<template>
    <div ref="brandLogoWrapper" class="w-36 h-16 xl:w-40 xl:h-20 mx-auto relative">
        <div class="logo" v-html="logos[0].data"></div>
        <div class="logo" v-html="logos[1].data"></div>
    </div>
</template>

<script>
import shuffle from 'lodash.shuffle';

export default {
    name: 'AnimatingBrandLogo',
    props: {
        logos: {
            type: Array,
            required: true,
        },
        delay: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.tween = this.$gsap.timeline({ repeat: -1, repeatDelay: 20, yoyo: true });

        this.tween.delay(this.delay);
        const hiddenPaths = shuffle(
            Array.from(this.$refs.brandLogoWrapper.children[0].querySelectorAll('path, rect, polygon'))
        );
        const visiblePaths = shuffle(
            Array.from(this.$refs.brandLogoWrapper.children[1].querySelectorAll('path, rect, polygon'))
        );
        // eslint-disable-next-line no-return-assign,no-param-reassign
        hiddenPaths.forEach((x) => (x.style.opacity = 0));

        this.tween.staggerTo(visiblePaths, 0.3, { opacity: 0 }, 0.1);
        this.tween.staggerTo(hiddenPaths, 0.3, { opacity: 1 }, 0.1);
    },
    beforeDestroy() {
        this.tween.kill();
    },
};
</script>

<style scoped>
.logo {
    @apply absolute top-0 left-0 w-full h-full;
}
</style>
